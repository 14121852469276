// import icons
import instagram from '../../assets/contact/instagram.png'
import facebook from '../../assets/contact/facebook.png'
import linkedin from '../../assets/contact/linkedin.png'
import discord from '../../assets/contact/discord.png'


// social media information
const SOCIAL_INFO = [
    {
        name: 'instagram',
        link: 'https://www.instagram.com/westernu.ai/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==',
        img: instagram,
    },
    {
        name: 'facebook',
        link: 'https://www.facebook.com/westernuai',
        img: facebook,
    },
    {
        name: 'linkedin',
        link: 'https://www.linkedin.com/company/westernuai/',
        img: linkedin,
    },
    {
        name: 'discord',
        link: 'https://discord.com/invite/u6MStSStB7',
        img: discord,
    }
]

export default SOCIAL_INFO
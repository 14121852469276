import '../css/Projects.css'
const Projects = () => {
    return (
        <section className = 'projects' /* id='projects' */ id = 'coming-soon'>
            {/* <div>displaying projects soon...</div> */}
            <div>a lot more to display soon...</div>
            <div>for now listen to our <a id = 'test' href = 'https://linktr.ee/westernuai' target='_blank'>podcast</a></div>
        </section>
    )
}
export default Projects;